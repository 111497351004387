import { ref } from 'vue';
import { projectStorage,projectAuth } from '../firebase/config';


const use_event_report_storage = () => {
    const error = ref(null);
  
    // url for download the image
    const url = ref(null);
  
    // Path for firestore
    const filePath = ref(null);
  
    const uploadFile = async (file,date,hour,name) => {
      filePath.value = `Event_reports/${date}/${hour}/${name}/${file.name}`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };
    const upload_signature_or_pdf = async (file,date,hour,name,fileName) => {
      filePath.value = `Event_reports/${date}/${hour}/${name}/${fileName}.pdf`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };

   
  
    // Delete the file
    const deleteImage = async path => {
      const storageRef = projectStorage.ref(path);
  
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };


    return { error, url, filePath, uploadFile,upload_signature_or_pdf, deleteImage };
};

export default use_event_report_storage;