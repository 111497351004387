<template>
  <div v-if="!state" class="event-report-main">
      <div class="title">
        <p>ביגוד רוסמן</p>
      </div>
      <div class="main-menu-options">
        <Button
                label="דוח נפגע עובד/לקוח חדש"
                style="width:260px; height:100px; margin:5px; font-size: 2rem;"
                class="p-button-success"
                @click="state='דוח חדש'"
        />
         <Button
            v-if="user_role>=99"
            label="ניהול דוחות"
            style="width:260px; height:100px; margin:5px; font-size: 2rem;"
            class="p-button-warning"
            @click="state='ניהול'"
        />
      </div> 
  </div>
  <div v-if="state" class="component">
    <EventReportNew @close="state=null" v-if="state=='דוח חדש'"/>
    <EventReportManage v-if="state=='ניהול'"  />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import EventReportNew from './components/EventReportNew.vue'
import EventReportManage from './components/EventReportManage.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '../../../store'
import { computed } from '@vue/runtime-core'


export default {
    components:{EventReportNew,EventReportManage},
    setup(){
        const state = ref(null)
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))
        onBeforeRouteLeave((to,from,next)=>{
          if(state.value){
            state.value = null
            next(false)
          }else{
            next()
          }
        })
        return{state,user_role}
    }
}
</script>

<style scoped>
    .event-report-main{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1.5rem;
        overflow-y: auto;
    }
    .main-menu-options {
        width: 350px;
        height: 80vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
    .component{
        width: 100%;
        height: 100%;
    }
</style>